<template>
  <div>
    <el-container>
      <el-header class="header" height="100px">
        <div class="header-content">
          <div class="logo">
            <img src="../assets/logo.png" height="66px" alt="" />
          </div>
          <div class="nav-box">
            <ul class="nav">
              <li><a href="#">网站首页</a></li>
              <li><a href="#">公司简介</a></li>
              <li><a href="#">产品展示</a></li>
              <li><a href="#">联系我们</a></li>
            </ul>
          </div>
        </div>
      </el-header>
      <div>
        <div class="main-box">
          <div class="content">
            <div class="description">
              <div><img src="../assets/about_1.png" /></div>
              <div class="txt">
                <div>
                  <span class="fontColor slg">
                    <span class="compony"> 好面来软件</span>良心选料 用心制品</span
                  >
                </div>
                <div>
                  好面来软件是一款小程序软件，主要为面粉厂家和用户提供便捷的取面、换面、积分、分享等操作。在好面来小程序上面粉厂家可以清楚的查看用户的小麦和面粉的存量，以及换面的记录，积分兑换的记录。
                  同时好面来小程序连接了智能称重的硬件系统，因此在取面和换面过程极大的减少了人工称重的成本。用户在小程序上也可以操作换面进行积分积累，也可进行分享从而积累积分，然后通过积分兑换商品，让用户体会到小程序积分功能的福利。
                </div>
                <div class="part-2">
                  
                </div>
              </div>
            </div>
          </div>
          <div class="product-box">
            <div class="product">
              <div class="item">
                <img src="../assets/product/homepro1.jpeg" />
                <span>家用高档粉</span>
              </div>
              <div class="item">
                <img src="../assets/product/homepro2.jpeg" />
                <span>推荐产品</span>
              </div>
              <div class="item">
                <img src="../assets/product/homepro3.jpeg" />
                <span>农副产品</span>
              </div>
              <div class="item">
                <img src="../assets/product/homepro4.jpeg" />
                <span>产品总览</span>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-txt">
          <span>好面来软件 © 2019-2022</span> <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">冀ICP备2022006848号</a>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  methods: {
    goBeian() {
      window.open('https://beian.miit.gov.cn/', '_blank')
    }
  }
};
</script>

<style lang="less">
@import "../assets/reset.less";
.el-header,
.el-footer {
  //   background-color: #b3c0d1;
  //   color: #333;
  text-align: center;
  line-height: 60px;
}
.el-main {
  //   background-color: #e9eef3;
  //   color: #333;
  text-align: center;
  line-height: 160px;
}
.header {
  .header-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .logo {
      display: flex;
      align-items: center;
      height: 100%;
      font-weight: bold;
      flex-direction: row;

      width: 250px;
      .compony-name {
        font-size: 35px;
        color: #000;
      }
      .logo-slg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // line-height: 10px;
        font-size: 16px;
        color: #2b9854;
      }
    }
    .nav-box {
      height: 86px;
      color: #000;
      font-size: 16px;
      display: flex;
      align-items: center;

      ul {
        display: flex;
        flex-direction: row;
        li {
          width: 100px;
          margin-left: 100px;
          a {
            display: block;
            text-transform: capitalize;
            text-decoration: none;
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            -o-transition: 0.5s;
            transition: 0.5s;
            color: #000;
            font-size: 16px;
          }
        }
      }
    }
  }
  border-bottom: 2px solid rgba(43, 152, 84, 1);
}
.main-box {
  // height: calc(100vh - 100px - 120px);
  .content {
    background-image: url("../assets/about_8.jpeg");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
    background-color: rgba(249, 249, 249, 1);
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
    .description {
      display: flex;
      flex-direction: row;
      align-items: center;
      .txt {
        width: 820px;
        line-height: 2;
        font-size: 16px;
        margin-left: 40px;
        position: relative;
        .part-2 {
          margin-top: 20px;
        }
      }
    }
  }
  .product-box {
    display: flex;
    align-content: center;
    margin: 20px 0;
    .product {
      display: flex;
      justify-content: center;
      width: 100%;
      .item {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}
.footer {
  height: 120px;
  // background-color: rgba(43, 152, 84, 1);
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 2px solid #2b9854;
  .footer-txt {
    font-size: 16px;
    display: flex;
    align-items: center;
    .beian {
      margin-left: 50px;
      cursor: pointer;
      color: black;
      text-decoration: underline;
      &:hover {
        color: #1b86eb
      }
    }
  }
}
.fontColor {
  color: #2b9854;
}
.slg {
  font-size: 20px;
  line-height: 1.8;
  font-weight: bold;
  position: absolute;
  top: -60px;
  .compony {
    color: black;
    &::after {
      content: "";
      border-right: 4px solid black;
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}
</style>